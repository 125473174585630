// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--mjQjv{padding-bottom:12px}.expanderHeading--mfSja{font-size:1.9rem;line-height:1.2;padding:9px 0 8px;text-align:center;margin:0 auto}.expanderHeadingIsLandscape--5EiEQ{text-align:left;margin:0}.expanderSmallText--4lCwF{font-size:1.2rem}.expPnlDet--7zRuj{max-height:375px;overflow:auto}.expPnlDetSections--I42ci,.expPnlDetServices--LBvbw{padding-bottom:15px}.expPnlDetTimes--0tQGd{padding-left:10px;padding-right:10px}.summaryMsgContainer--j9jlR{position:absolute;top:5px;right:5px;display:flex;flex-direction:column;align-items:flex-end}.popperContent--Ihgeb{padding:2px 5px;margin-top:3px}.popperContent--Ihgeb:first-child{margin-top:0}.popperText--HbfES{font-size:.9rem}.loaderWrap--tooh9{width:100%}.loaderWrapIsCentered--ZFabS{display:flex;justify-content:center}.servicesAlert--ZtSzU,.sectionsAlert--eBV26,.coversAlert--xhRF4{margin-top:12px}.verificationHeader--j9fr4{width:100%;padding:8px;color:#fff;margin:10px 0px;font-weight:bold;border:2px solid #000;background-color:rgba(207,42,39,.76863)}.addBottomMargin--LqKGp{margin-bottom:10px}.paperBox--iXSqB{padding:25px 17px 10px}.paperBoxInner--HYfCm{padding:0 8px}.ruleWrap--GjaR3{padding:0 8px}.ruleWrapNoText--TsIT-{margin-top:20px;margin-bottom:20px}.gawWrap--e2W3a{padding:0 8px}.shrinkTopOnHeading--i5Sex{margin-top:-24px;margin-bottom:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--mjQjv",
	"expanderHeading": "expanderHeading--mfSja",
	"expanderHeadingIsLandscape": "expanderHeadingIsLandscape--5EiEQ",
	"expanderSmallText": "expanderSmallText--4lCwF",
	"expPnlDet": "expPnlDet--7zRuj",
	"expPnlDetSections": "expPnlDetSections--I42ci",
	"expPnlDetServices": "expPnlDetServices--LBvbw",
	"expPnlDetTimes": "expPnlDetTimes--0tQGd",
	"summaryMsgContainer": "summaryMsgContainer--j9jlR",
	"popperContent": "popperContent--Ihgeb",
	"popperText": "popperText--HbfES",
	"loaderWrap": "loaderWrap--tooh9",
	"loaderWrapIsCentered": "loaderWrapIsCentered--ZFabS",
	"servicesAlert": "servicesAlert--ZtSzU",
	"sectionsAlert": "sectionsAlert--eBV26",
	"coversAlert": "coversAlert--xhRF4",
	"verificationHeader": "verificationHeader--j9fr4",
	"addBottomMargin": "addBottomMargin--LqKGp",
	"paperBox": "paperBox--iXSqB",
	"paperBoxInner": "paperBoxInner--HYfCm",
	"ruleWrap": "ruleWrap--GjaR3",
	"ruleWrapNoText": "ruleWrapNoText--TsIT-",
	"gawWrap": "gawWrap--e2W3a",
	"shrinkTopOnHeading": "shrinkTopOnHeading--i5Sex"
};
export default ___CSS_LOADER_EXPORT___;
