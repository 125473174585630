import {connect} from 'react-redux';
import FooterNav from 'shared-components/footer-nav';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {footerNavTypes, IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {NavigationActionsNS} from 'app/actions/navigation/navigationActions';
import FooterService from 'app/services/footer/footer.service';
import {ROUTE_NAMES} from 'app/services/route/route.types';
import {BookingActionsNS} from 'app/actions/booking/bookingActions';
import {BookingService} from 'app/services/booking/booking.service';
import {StandbyActionsNS} from "app/actions/standby/standbyActions";
import {servicePaymentType, IServicePaymentDetails} from "shared-types/index";
import { IWidgetModel } from 'app/models';
import { INavigation } from 'app/reducers/state';

const NS = 'FooterNavContainer';
export const enableRobotValidationOnBookingNow = (widget: IWidgetModel) => {
  // this is set to true when the bookingNow robot validation is enabled or the editBooking robot validation is enabled and the user is editing the booking
  const settings = widget.activeVenue?.widgetSettings;
  if (!settings) return false;
  const {enableRobotValidation, enableCompleteBookingRobotValidation, enableEditRobotValidation} = settings;
  const isEditMode = !!widget.savedBooking;
  return (
    enableRobotValidation && (
      enableCompleteBookingRobotValidation || (enableEditRobotValidation && isEditMode)
    )
  )
}
export const _isDisableBookingNow = (navigation: INavigation, widget: IWidgetModel, nextType: footerNavTypes) => {
  return !navigation.passRobotCheck  // this is set to false when the user passes the robot check
    && nextType === footerNavTypes.book // this is set to true when the user is on the summary page
    && enableRobotValidationOnBookingNow(widget);
}
const mapStateToProps = ({navigation, widget}: IRootState): IStateFromProps => {
  const summaryRouteIndex: number = navigation.stepRoutes.indexOf(ROUTE_NAMES.SUMMARY);
  const isSummary: boolean = navigation.currentStepIndex === summaryRouteIndex;

  let nextType: footerNavTypes = footerNavTypes.next;
  const {activeService, booking} = widget;
  const saveBooking = widget.savedBooking;
  if (isSummary) {
    const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;
    const shouldPay = BookingService.shouldPay(activeService, booking);

    if (shouldPay) {
      nextType = paymentDetails.paymentType === servicePaymentType.preAuth
        ? footerNavTypes.preauth
        : footerNavTypes.pay;
    } else {
      nextType = footerNavTypes.book;
    }
  }

  const customerDetailsRouteIndex: number = navigation.stepRoutes.indexOf(ROUTE_NAMES.CUSTOMER_DETAILS);
  const isCustomerDetails: boolean = navigation.currentStepIndex === customerDetailsRouteIndex;
  if (isCustomerDetails && widget.standbyData) {
    nextType = footerNavTypes.standby;
  }
  const isDisableBookingNow = _isDisableBookingNow(navigation, widget, nextType);
  return {
    theme: widget.theme,
    wrapperStyle: widget.wrapperStyle,
    isEditMode: !!saveBooking,
    nextEnabled: FooterService.isNextEnabled(navigation, widget),
    prevEnabled: FooterService.isPrevEnabled(navigation, widget),
    nextType,
    blockNav: widget.blockNav,
    venuePhone: widget.activeVenue ? widget.activeVenue.phone : '',
    showNextLoader: navigation.loadingPaymentOnNext,
    isDisableBookingNow
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleNavPressed: (type: footerNavTypes) => {

      switch (type) {
        case footerNavTypes.book:
          dispatch(BookingActionsNS.saveBooking());
          break;

        case footerNavTypes.pay:
        case footerNavTypes.preauth:
          dispatch(BookingActionsNS.saveBooking());
          break;

        case footerNavTypes.standby:
          dispatch(StandbyActionsNS.saveToStandbyList());
          break;

        default:
          dispatch(NavigationActionsNS.navPressed(type));
      }
    },
    handleDisabledNextPressed: () => {
      dispatch(NavigationActionsNS.triedNext());
    },
    handleCancelBooking: () => {
      dispatch(BookingActionsNS.cancelBooking());
    }
  }
};

const FooterNavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterNav as any);

export default FooterNavContainer;
