import { connect } from 'react-redux';
import Sitting from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './types';
import { ThunkDispatch } from 'redux-thunk';

const NS = 'SittingContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {

  const {editBookingDisabled, activeVenue, wrapperStyle} = widget;
  const venuePhone: string = activeVenue ? activeVenue.phone : '';

  return {
    editBookingDisabled,
    venuePhone,
    wrapperStyle
  };
};


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    
  }
};

const SittingContainer = connect(
  mapStateToProps, 
  mapDispatchToProps
)(Sitting as any);

export default SittingContainer;